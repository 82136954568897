import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    mertua: {
      id: '',
      NIK: null,
      nama: '',
      ibu: {
        NIK: null,
        nama: '',
        tempatLahir: '',
        tglLahir: '',
        aktaMeninggal: null,
        tglMeninggal: null
      },
      bapak: {
        NIK: null,
        nama: '',
        tempatLahir: '',
        tglLahir: '',
        aktaMeninggal: null,
        tglMeninggal: null
      }
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.mertua = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})
