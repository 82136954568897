<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            x-small
            fab
            text
            @click="getData()"
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data Ortu</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1"
          >Edit Data Orang Tua</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <v-divider></v-divider>
          <div class="pa-2"><b>Data Ibu</b></div>
          <v-divider></v-divider>
          <!-- Nama Ibu -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Ibu</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="namaIbu"
                  hint="Nama Ibu"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="NIKIbu"
                  hint="NIK Ibu"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal Lahir -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal Lahir</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="tempatLahirIbu"
                  hint="Tempat Lahir"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglLahirIbuPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglLahirIbu"
                      hint="Tanggal Lahir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglLahirIbu"
                    @input="tglLahirIbuPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Kematian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Kematian</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="aktaMeninggalIbu"
                  hint="Akta Meninggal"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglMeninggalIbuPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglMeninggalIbu"
                      hint="Tanggal Meninggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglMeninggalIbu"
                    @input="tglMeninggalIbuPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-divider></v-divider>
          <div class="pa-2"><b>Data Ayah</b></div>
          <v-divider></v-divider>
          <!-- Nama Ayah -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Ayah</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="namaAyah"
                  hint="Nama Ayah"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="NIKAyah"
                  hint="NIK Ayah"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal Lahir -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal Lahir</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="tempatLahirAyah"
                  hint="Tempat Lahir"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglLahirAyahPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglLahirAyah"
                      hint="Tanggal Lahir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglLahirAyah"
                    @input="tglLahirAyahPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Kematian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Kematian</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="aktaMeninggalAyah"
                  hint="Akta Meninggal"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglMeninggalAyahPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglMeninggalAyah"
                      hint="Tanggal Meninggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglMeninggalAyah"
                    @input="tglMeninggalAyahPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from '@/helper/cookie.js'
import apiURL from '@/helper/getURL.js'
import Auth from '@/helper/auth.js'

import refreshView from '@/store/asn/rworangtua/viewRworangtua'

export default {
  name: 'rworangtuaModalEdit',

  data: () => ({
    token: Cookie.get('token'),
    ModalEdit: false,
    btnLoading: true,

    // Date Picker
    tglLahirIbuPicker: false,
    tglLahirAyahPicker: false,
    tglMeninggalIbuPicker: false,
    tglMeninggalAyahPicker: false,

    NIKIbu: '',
    namaIbu: '',
    tempatLahirIbu: '',
    tglLahirIbu: '',
    aktaMeninggalIbu: '',
    tglMeninggalIbu: null,

    NIKAyah: '',
    namaAyah: '',
    tempatLahirAyah: '',
    tglLahirAyah: '',
    aktaMeninggalAyah: '',
    tglMeninggalAyah: null,
  }),

  methods: {
    reset() {
      this.$refs.form.reset()
    },

    async getData() {
      this.get_nip = this.$route.params.id
      const token = await Cookie.get('token')
      const url = apiURL.baseURL + 'ortu/nip/' + this.get_nip

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          this.NIKIbu = response.data.mapData.DataKeluarga.dataOrtu.ibu.NIK
          this.namaIbu = response.data.mapData.DataKeluarga.dataOrtu.ibu.nama
          this.tempatLahirIbu =
            response.data.mapData.DataKeluarga.dataOrtu.ibu.tempatLahir
          this.tglLahirIbu =
            response.data.mapData.DataKeluarga.dataOrtu.ibu.tglLahir
          this.aktaMeninggalIbu =
            response.data.mapData.DataKeluarga.dataOrtu.ibu.aktaMeninggal
          this.tglMeninggalIbu =
            response.data.mapData.DataKeluarga.dataOrtu.ibu.tglMeninggal

          this.NIKAyah = response.data.mapData.DataKeluarga.dataOrtu.bapak.NIK
          this.namaAyah = response.data.mapData.DataKeluarga.dataOrtu.bapak.nama
          this.tempatLahirAyah =
            response.data.mapData.DataKeluarga.dataOrtu.bapak.tempatLahir
          this.tglLahirAyah =
            response.data.mapData.DataKeluarga.dataOrtu.bapak.tglLahir
          this.aktaMeninggalAyah =
            response.data.mapData.DataKeluarga.dataOrtu.bapak.aktaMeninggal
          this.tglMeninggalAyah =
            response.data.mapData.DataKeluarga.dataOrtu.bapak.tglMeninggal
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status)
          } else {
            console.log('Error', error.message)
          }
        })
    },

    async edit() {
      let token = await Cookie.get('token')
      this.btnLoading = false
      let data = {
        dataOrtu: {
          ibu: {
            sapk: {
              id: null,
            },
            NIK: this.NIKIbu,
            nama: this.namaIbu,
            tempatLahir: this.tempatLahirIbu,
            tglLahir: this.tglLahirIbu,
            aktaMeninggal: this.aktaMeninggalIbu,
            tglMeninggal: this.tglMeninggalIbu,
          },
          bapak: {
            sapk: {
              id: null,
            },
            NIK: this.NIKAyah,
            nama: this.namaAyah,
            tempatLahir: this.tempatLahirAyah,
            tglLahir: this.tglLahirAyah,
            aktaMeninggal: this.aktaMeninggalAyah,
            tglMeninggal: this.tglMeninggalAyah,
          },
        },
      }
      const url = apiURL.baseURL + 'ortu/nip/' + this.get_nip + '/ortu'
      this.http
        .put(url, data, { headers: apiURL.Update(token).headers })
        .then((response) => {
          this.btnLoading = true
          if (response.data.success) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('berhasilAlert', true)
            refreshView.commit('gagalAlert', false)
            refreshView.commit('success', response.data.success)
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', response.data.success)
          }
          this.closeModal()
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', error.response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', error.response.data.success)
            console.log(error.response.status)
            this.btnLoading = true
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', error.response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', error.response.data.success)
            console.log(error.response.status)
            console.log('Error', error.message)
            this.btnLoading = true
          }
        })
    },

    closeModal() {
      this.reset()
      this.ModalEdit = false
    },
  },
}
</script>
