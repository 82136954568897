<template>
  <v-dialog
    v-model="MertuaModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">Edit Data Mertua</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <v-divider></v-divider>
          <div class="pa-2"><b>Data Ibu</b></div>
          <v-divider></v-divider>

          <!-- Nama Ibu -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Ibu</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="editedItem.ibu.nama"
                  hint="Nama Ibu"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editedItem.ibu.NIK"
                  hint="NIK Ibu"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal Lahir -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal Lahir</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="editedItem.ibu.tempatLahir"
                  hint="Tempat Lahir"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglLahirIbuPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.ibu.tglLahir"
                      hint="Tanggal Lahir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.ibu.tglLahir"
                    @input="tglLahirIbuPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Kematian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Kematian</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="editedItem.ibu.aktaMeninggal"
                  hint="Akta Meninggal"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglMeninggalIbuPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.ibu.tglMeninggal"
                      hint="Tanggal Meninggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.ibu.tglMeninggal"
                    @input="tglMeninggalIbuPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-divider></v-divider>
          <div class="pa-2"><b>Data Ayah</b></div>
          <v-divider></v-divider>

          <!-- Nama Ayah -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Ayah</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="editedItem.bapak.nama"
                  hint="Nama Ayah"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editedItem.bapak.NIK"
                  hint="NIK Ayah"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal Lahir -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal Lahir</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="editedItem.bapak.tempatLahir"
                  hint="Tempat Lahir"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglLahirAyahPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.bapak.tglLahir"
                      hint="Tanggal Lahir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.bapak.tglLahir"
                    @input="tglLahirAyahPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Kematian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Kematian</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="editedItem.bapak.aktaMeninggal"
                  hint="Akta Meninggal"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglMeninggalAyahPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.bapak.tglMeninggal"
                      hint="Tanggal Meninggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.bapak.tglMeninggal"
                    @input="tglMeninggalAyahPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import refreshView from "@/store/asn/rworangtua/viewRworangtua";
import modal_editState from "@/store/asn/rworangtua/modal_edit";

export default {
  name: "rworangtuaMertuaModalEdit",

  computed: {
    MertuaModalEdit: {
      get() {
        return modal_editState.state.ModalEdit;
      },

      set(value) {
        modal_editState.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.mertua;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    btnLoading: true,
    mertua: {},
    get_nip: "",

    // Date Picker
    tglLahirIbuPicker: false,
    tglLahirAyahPicker: false,
    tglMeninggalIbuPicker: false,
    tglMeninggalAyahPicker: false,
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.mertua = {
        id: "",
        NIK: null,
        nama: "",
        ibu: {
          NIK: null,
          nama: "",
          tempatLahir: "",
          tglLahir: "",
          aktaMeninggal: null,
          tglMeninggal: null,
        },
        bapak: {
          NIK: null,
          nama: "",
          tempatLahir: "",
          tglLahir: "",
          aktaMeninggal: null,
          tglMeninggal: null,
        },
      };
    },

    async edit() {
      this.btnLoading = false;

      let data = {
        id: this.editedItem.id,
        ibu: {
          NIK: this.editedItem.ibu.NIK,
          nama: this.editedItem.ibu.nama,
          tempatLahir: this.editedItem.ibu.tempatLahir,
          tglLahir: this.editedItem.ibu.tglLahir,
          aktaMeninggal: this.editedItem.ibu.aktaMeninggal,
          tglMeninggal: this.editedItem.ibu.tglMeninggal,
        },
        bapak: {
          NIK: this.editedItem.bapak.NIK,
          nama: this.editedItem.bapak.nama,
          tempatLahir: this.editedItem.bapak.tempatLahir,
          tglLahir: this.editedItem.bapak.tglLahir,
          aktaMeninggal: this.editedItem.bapak.aktaMeninggal,
          tglMeninggal: this.editedItem.bapak.tglMeninggal,
        },
      };

      const url = process.env.VUE_APP_ASN + "ortu/nip/" + this.get_nip + "/mertua";

      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    async closeModal() {
      await this.reset();
      await modal_editState.commit("editModal", Object.assign({}, this.mertua));
      await refreshView.commit("refreshData", true);
      this.MertuaModalEdit = false;
    },
  },
};
</script>
